import React from 'react';
import { Link, graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { defaultSerializers } from '../utils/serializers';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { addHotspot } from '../utils/imageUrl';
import ShowsAside from '../components/ShowsAside';

const About = ({ data }) => (
  <Layout
    headerImage={addHotspot(
      data.sanityPage.bannerImage.asset.fluid.src,
      data.sanityPage.bannerImage.hotspot
    )}
    sidebar={<ShowsAside />}
  >
    <SEO title="Solo Touring" />
    <article className="page-content page-content--about">
      {/* <header>
        <h1>Hello,</h1>
      </header> */}

      <BlockContent
        blocks={data.sanityPage._rawIntro}
        serializers={defaultSerializers}
      />
    </article>
  </Layout>
);

export const pageQuery = graphql`
  {
    sanityPage(slug: { current: { eq: "shows" } }) {
      title
      _rawIntro(resolveReferences: { maxDepth: 5 })
      bannerImage {
        hotspot {
          x
          y
        }
        asset {
          metadata {
            palette {
              dominant {
                background
                title
              }
            }
          }
          fluid(maxWidth: 1600, maxHeight: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sanityGallery(slug: { current: { eq: "press-photos" } }) {
      title
      media {
        ... on SanityGalleryImage {
          title
          image {
            asset {
              url
              extension
            }
          }
          description
          slug {
            current
          }
        }
        ... on SanityGalleryVideo {
          title
          vimeoURL
          youtubeURL
          description
          slug {
            current
          }
        }
      }
    }
  }
`;

export default About;
